
#Header {
    width: 100%;
}

.titleImg {
}

.titleImg img {
    width: 100%;
    height: auto;
}


* {
    margin: 0;
    padding: 0;

    font-family: 'Raleway', sans-serif;
    font-size: 1em;
}


h1, h2, h3 {
    font-family: 'Pacifico', cursive;
}

h1 {
    font-size: 4em;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(225, 0, 245, 1.0);
}

h2 {
    font-size: 3em;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(225, 0, 245, 1.0);
}

h3 {
    font-size: 1em;
}

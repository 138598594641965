
#ContactUsSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;  /* default value, can be omitted */
    justify-content: center; /* align items to the start, can be changed to 'center', 'space-between', etc. */
    gap: 2em; /* optional: space between cards */
    margin-top: 4em;
    margin-bottom: 4em;
}

#ContactUsSection h2 {
    font-family: 'Pacifico', cursive;
    font-size: 1.2em;
    text-align: center;
    padding-bottom: 2em;
}

#ContactUsSection .card-deck {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;  /* default value, can be omitted */
    justify-content: center; /* align items to the start, can be changed to 'center', 'space-between', etc. */
    gap: 2em; /* optional: space between cards */
}

#ContactUsSection .card {
    width: 14em;

    margin-top: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
}

#ContactUsSection .card-title {
    font-family: 'Pacifico', cursive;
    text-align: center;

    margin:  0.5em 1em 0.5em 1em;
}

#ContactUsSection .card-title h2 {

}

#ContactUsSection .card-title p {

}

#ContactUsSection .card-title i {
    font-size: 4em;
}


@import "~@flaticon/flaticon-uicons/css/all/all";

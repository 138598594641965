#IntroductionSection {

}

.Intro {
    margin: 0 6em 4em 6em;
}

.Intro h2 {
    font-family: 'Pacifico', cursive;
    font-size: 2em;

    text-align: center;
    margin-top: 4em;

    text-shadow: 1px 2px 8px rgba(255, 0, 255, 1.0);
}

.Intro h3 {
    font-family: 'Pacifico', cursive;
    font-size: 2em;

    text-align: left;
    margin-top: 1.2em;

    text-shadow: 1px 2px 8px rgba(255, 0, 255, 1.0);
}

.Intro p {
    text-align: left;
}

#FindUsSection {
    margin-bottom: 4em;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;  /* default value, can be omitted */
    justify-content: center; /* align items to the start, can be changed to 'center', 'space-between', etc. */
    gap: 2em; /* optional: space between cards */
}

#FindUsSection h2 {
    font-family: 'Pacifico', cursive;
}

#FindUsSection .card {
    width: 14em;

    margin-top: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
}


/* Styles here will apply to devices with a screen width of 767 pixels or smaller */
@media (max-width: 767px) {
    .MapCanvas {
        max-width: 100%;
        width: 380px;
        height: 300px;

        list-style-type: none;
        transition: none;
        overflow: hidden;
    }
}


@media (min-width: 768px) {
    .MapCanvas {
        max-width: 100%;
        width: 600px;
        height: 350px;

        list-style-type: none;
        transition: none;
        overflow: hidden;
    }
}

#ServicesCardDeck {
    position: relative;
    width: 100%;
    margin: 4em 0 4em 0;
    padding: 4em;
    background-color: #fef;
}

#ServicesCardDeck::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 40px; /* Adjust this for the height of the fade effect */
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    pointer-events: none;

    top: 0;
    transform: rotate(180deg);
}

#ServicesCardDeck::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 100px; /* Adjust this for the height of the fade effect */
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    pointer-events: none;
}

#ServicesCardDeck h2 {
  text-align: justify;
  padding-bottom: 2em;
}

#ServicesCardDeck .card-deck {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;  /* default value, can be omitted */
    justify-content: center; /* align items to the start, can be changed to 'center', 'space-between', etc. */
    gap: 2em; /* optional: space between cards */
}

#ServicesCardDeck .card {
    width: 14em;

    margin-top: 1em;
    padding-top: 2em;
    padding-bottom: 2em;

    justify-content: center;
    align-content: center;
}

#ServicesCardDeck .card-title h3 {
    font-family: 'Pacifico', cursive;
    text-align: center;


    margin:  0.5em 1em 0.5em 1em;
}

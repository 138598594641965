
.FooterSection {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 4em;
    color: #fff;
    background-color: #000;
}

.FooterSection::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 40px; /* Adjust this for the height of the fade effect */
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    pointer-events: none;

    top: 0;
    transform: rotate(180deg);
}

.FooterSection h1 {
    font-family: 'Pacifico', cursive;
    font-size: 2em;
}

.FooterSection h2 {
    font-family: 'Pacifico', cursive;
    font-size: 1.2em;
}

.FooterSection h3 {
    font-family: 'Pacifico', cursive;
    font-size: 1em;
}

.logoSmall {
    text-align: center;
    margin-bottom: 0em;
}

.llamaSection {
    margin: 0;
    padding: 0;
    color: #fff;
    background-color: #000;

    font-size: 0.6em;
    text-align: center;

    padding-bottom: 1em;
}

.FooterSection ul {
    list-style-type: none;
    display: flex; /* display list items horizontally */
    justify-content: space-around;
    gap: 2em;
}
